<template>
  <data-form2
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import StationsDataService from "../../services/stations.service";

export default {
  name: "stations-form",
  props: ["data"],
  components: {
    DataForm2,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [{ code: { label: "Šifra", type: "text" } }, {}, {}, {}],
          [{ description: { label: "Opis", type: "text" } }],
          [{ divider: { type: "divider" } }],
          [
            { metroplex: { label: "Metroplex", type: "text" } },
            { active: { label: "Aktivan", type: "checkbox" } },
          ],
          [{ divider: { type: "divider" } }],
          [
            { nominal: { label: "Nominal", type: "text" } },
            {
              type: {
                label: "Tip",
                type: "dropdown",
                list: [
                  { id: "", name: "-" },
                  { id: "Office", name: "Office" },
                  { id: "Meet & Greet", name: "Meet & Greet" },
                ],
              },
            },
            { email: { label: "Email", type: "email" } },
            {
              sale_type: {
                label: "MP/VP",
                type: "dropdown",
                list: [
                  { id: "", name: "-" },
                  { id: "MP", name: "MP" },
                  { id: "VP", name: "VP" },
                ],
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            { street: { label: "Ulica", type: "text" } },
            { city: { label: "Grad", type: "text" } },
            { zip_code: { label: "Poštanski broj", type: "text" } },
            // { state: { label: "Drzava", type: "text" } },
            { country: { label: "Drzava", type: "text" } },
          ],
          // [{ country: { label: "Drzava", type: "text" } }, {}, {}, {}],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      StationsDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Magacin je promenjen!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      StationsDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Magacin je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>